import React from "react"
import styled from "styled-components"

const ListWrapper = styled.div`
  ul {
    list-style: none;

    li::before {
      content: "\\25AA";
      color: ${props => props.theme.colors.orange};
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    li {
      padding: 0 0 0.5rem 0;

      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
`

const ResponsiveListWrapper = styled(ListWrapper)`
  @media (min-width: 1024px) {
    padding: 8px 16px 0 0;

    ul {
      p {
        margin-block-end: 24px;
      }
    }
  }

  @media (max-width: 1023px) {
    margin: 0 -16px 24px;
  }
`

const Wrapper = ({ list }) => {
  return (
    <ResponsiveListWrapper>
      <ul>
        {list.map((item, index) => {
          return <li key={`item-${index}`}>{item}</li>
        })}
      </ul>
    </ResponsiveListWrapper>
  )
}

export default Wrapper
