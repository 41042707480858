import React from "react"
import styled from "styled-components"
import { Box, Grid } from "@material-ui/core"

import NextArrow from "@assets/jobs/next-arrow.inline.svg"

import { LATERAL_MOVEMENT_PREFERENCES } from "@constants/index"

import { getJobTitle } from "@helpers/job"

const OuterGrid = styled(Grid)`
  align-items: center;

  background: ${props =>
    props.isMostPreferred
      ? props.theme.colors.boxGreen
      : `${props.theme.colors.boxLightGreen}4D`};
  color: ${props =>
    props.isMostPreferred
      ? props.theme.colors.textBlack
      : props.theme.colors.textBlack};

  * svg {
    width: 16px;
    height: 16px;
    fill: ${props =>
      props.isMostPreferred
        ? props.theme.colors.textGrey
        : props.theme.colors.boxLightGreen};
  }

  span.most-preferred {
    font-weight: 400;
    font-size: 8px;
    line-height: 20px;
  }
`

const ContentBox = styled(Box)`
  .name {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }

  .meta-info {
    color: ${props =>
      props.isMostPreferred
        ? props.theme.colors.textBlack
        : props.theme.colors.textLightGreen};

    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
  }
`

const JobBox = job => {
  const isMostPreferred =
    job.preference === LATERAL_MOVEMENT_PREFERENCES.MOST_PREFERRED

  return (
    <OuterGrid container spacing={1} isMostPreferred={isMostPreferred}>
      <Grid item xs={6}>
        <ContentBox p={2} isMostPreferred={isMostPreferred}>
          <Box pb={1} className="name">
            {getJobTitle(job)}
          </Box>
          <Box className="meta-info">
            {job.sector} | {job.department}
          </Box>
        </ContentBox>
      </Grid>

      <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
        <Grid item>
          {/* display: "inline-flex" for vertical center */}
          <Box pr={2} style={{ display: "inline-flex" }}>
            {isMostPreferred ? (
              <span className="most-preferred">Most Preferred</span>
            ) : (
              ""
            )}

            <NextArrow />
          </Box>
        </Grid>
      </Grid>
    </OuterGrid>
  )
}

export default JobBox
