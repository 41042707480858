import React from "react"
import styled from "styled-components"

const Element = styled.hr`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

const MobileHr = props => {
  return <Element />
}

export default MobileHr
