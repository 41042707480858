import React from "react"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import camelCase from "lodash/camelCase"

import nl2br from "nl2br"

import theme from "@assets/styles/theme"

import { Box } from "@material-ui/core"

import PotentialMovementChart from "@components/modules/PotentialMovementChart"
// import MobileSidebar from "@components/modules/job/MobileSidebar"
import AppLayout from "@components/layout/App"
import MobileHr from "@components/elements/MobileHr"

import CompetencyPanel from "@components/modules/job/CompetencyPanel"
import ListWrapper from "@components/modules/job/ListWrapper"

import { GRADE_TEXT_MAPPING, COMPETENCY_TYPES } from "@constants/index"
import { getJobTitle } from "@helpers/job"

const Content = styled.div`
  padding: 1rem 1.5rem;
`

const Flexbox = styled.div`
  display: flex;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const MainCol = styled.div`
  flex: 0 0 60%;
  margin-right: 1rem;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`

const DesktopContent = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const MobileContent = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`

const DesktopTitle = styled.h1`
  @media (max-width: 1023px) {
    display: none;
  }
`

const JobDescription = styled.p`
  line-height: 20px;
  margin: 0 0 24px 0;
`

const Header = styled.div`
  font-size: 1.25rem;
  padding: 1rem 0 0.2rem 0;

  @media (max-width: 1023px) {
    color: ${props => props.theme.colors.mainMenu};
    text-align: center;
  }
`

const MobileHeader = styled.div`
  color: ${props => props.theme.colors.mainMenu};
  padding: 0.5rem 0;
  text-align: center;
  display: none;
  font-size: 1.25rem;

  @media (max-width: 1023px) {
    display: block;
  }
`

const SectionDescription = styled.div`
  padding: 0.5rem 0;
  font-size: 0.825rem;
  color: #2a2a2a;

  @media (max-width: 1023px) {
    text-align: center;
  }
`

const SubCol = styled.div`
  flex: 1 0;
  margin-left: 1rem;

  @media (max-width: 1023px) {
    margin-left: 0;
  }
`

const SubColPanel = styled.div`
  padding-bottom: 16px;
  background-color: #f5f7f8;
`

const SectionHeader = styled.div`
  padding: 0 1rem 0.5rem 0.5rem;
  color: ${theme.colors.orange};
  border-bottom: solid 2px ${theme.colors.orange};
  font-size: 1.25rem;

  @media (max-width: 1023px) {
    padding: 0.5rem 0;
    border: none;
    text-align: center;
  }
`

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const MetaBox = styled(Box)`
  color: ${props => props.theme.colors.orange};

  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  margin-top: -12px;
  margin-bottom: 24px;
`

const JobPage = props => {
  const { pageContext } = props

  const modalClasses = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalSkill, setModalSkill] = React.useState({})

  const description = nl2br(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie elit id ipsum aliquet rhoncus. Fusce ultrices sapien quis enim lobortis pharetra. Aliquam at mattis mauris. Donec tristique tellus magna, sed ultricies quam porta id.
  
  Duis sed urna consequat, facilisis est blandit, efficitur leo. Aenean enim libero, condimentum sit amet posuere eget, suscipit vitae magna. Nulla nec imperdiet dui, et vestibulum dui. Duis urna lectus, venenatis sed efficitur sed, consectetur eget eros. Suspendisse potenti. Maecenas ac turpis vitae mauris volutpat efficitur egestas eget nibh. Etiam viverra lobortis nisi vulputate pellentesque.`)

  const technicalCompetencies = pageContext.technicalCompetencies.map(tc => {
    // Same as technical-competencies-parser
    let words = tc.split(":").map(s => s.trim())
    // TODO: hotfix for missing data
    if (words.length === 1) {
      words[1] = ""
    }

    const level = words[1]
    const slug = camelCase(words[0].replace(/\&/g, "and"))

    return {
      slug,
      level,
    }
  })

  const handleModalOpen = skill => {
    setModalOpen(true)
    setModalSkill({ ...modalSkill, ...skill })
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const renderMetaInfo = job => {
    const { sector, department, grade } = job
    const gradeText = GRADE_TEXT_MAPPING[grade]
      ? `Grade ${GRADE_TEXT_MAPPING[grade]}`
      : ""

    const text = [sector, department, gradeText]
      .filter(s => s.length !== 0)
      .join(" | ")

    return text.length !== 0 ? <MetaBox>{text}</MetaBox> : ""
  }

  return (
    <AppLayout>
      {/* <MobileSidebar
        title={pageContext.title}
        jobFamily={pageContext.jobFamily}
        isShowNav={true}
      /> */}

      <Content>
        {/* <div scroll-spy="job">
          <MobileHeader>Job Description</MobileHeader>
        </div> */}

        <Flexbox>
          <MainCol>
            <DesktopTitle>{getJobTitle(pageContext)}</DesktopTitle>
            {renderMetaInfo(pageContext)}

            {/* <JobDescription
              dangerouslySetInnerHTML={{ __html: description }}
            ></JobDescription> */}

            <hr />

            {/* <MobileContent>
              <div scroll-spy="competencies">
                <SectionHeader>Competencies</SectionHeader>
              </div>

              <CompetenciesListWrapper>
                <ul>
                  <li>Innovation</li>
                  <p>{pageContext.competencies.innovation}</p>
                </ul>
                <ul>
                  <li>Continuous Improvement</li>
                  <p>{pageContext.competencies.continuousImprovement}</p>
                </ul>
                <ul>
                  <li>Process Management Product Improvement</li>
                  <p>
                    {
                      pageContext.competencies
                        .processManagementProductImprovement
                    }
                  </p>
                </ul>
              </CompetenciesListWrapper>
              <MobileHr />
            </MobileContent> */}

            {/* TODO: change spy to competencies */}
            <div scroll-spy="skills">
              <Header>Competencies</Header>
              <SectionDescription>
                Hover on any of the competencies below to view a detailed
                description.
              </SectionDescription>

              <DesktopContent>
                <Flexbox>
                  <CompetencyPanel
                    {...{
                      competencies: pageContext.coreCompetencies,
                      type: COMPETENCY_TYPES.CORE,
                    }}
                  />
                </Flexbox>

                {pageContext.grade === "ONE" ? (
                  <Flexbox>
                    <CompetencyPanel
                      {...{
                        competencies: pageContext.leadershipCompetencies,
                        type: COMPETENCY_TYPES.LEADERSHIP,
                      }}
                    />
                  </Flexbox>
                ) : (
                  ""
                )}

                <Flexbox>
                  <CompetencyPanel
                    {...{
                      competencies: technicalCompetencies,
                      type: COMPETENCY_TYPES.TECHNICAL,
                    }}
                  />
                </Flexbox>
              </DesktopContent>

              {/* <MobileContent>
                <CompetencyPanel className="mobile">
                  {pageContext.coreCompetencies.map((cp, index) => {
                    return (
                      <SkillsTooltip>
                        <span
                          key={`core-competency-${index}`}
                          className="skill"
                          onClick={() => handleModalOpen(cp)}
                        >
                          {cp.name}
                        </span>
                      </SkillsTooltip>
                    )
                  })}
                </CompetencyPanel>
                <Modal
                  open={modalOpen}
                  onClose={handleModalClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div style={modalStyle} className={modalClasses.paper}>
                    <h2 id="simple-modal-title">{modalSkill.name}</h2>
                    <p id="simple-modal-description">
                      {modalSkill.description}
                    </p>
                  </div>
                </Modal>
              </MobileContent> */}
            </div>

            <MobileHr />
          </MainCol>

          <SubCol>
            <div scroll-spy="movements" style={{ paddingBottom: "24px" }}>
              <DesktopContent>
                <Box p={2}>
                  <SectionHeader>Potential Movements</SectionHeader>
                  <SubColPanel>
                    <PotentialMovementChart {...pageContext} />
                  </SubColPanel>
                </Box>
              </DesktopContent>

              {/* <MobileContent>
                <PotentialMovementChart
                  jobFamily={pageContext.jobFamily}
                  potentialMovements={[
                    ...pageContext.potentialMovements,
                  ].reverse()} // copy object instead of change of reference
                  isReverse={true}
                  currentJobId={pageContext.id}
                />
              </MobileContent> */}
            </div>

            <DesktopContent>
              <Box p={2}>
                {/* TODO: scroll-spy="" */}
                <div>
                  <SectionHeader>Key Accountabilities</SectionHeader>
                </div>

                <SubColPanel>
                  <ListWrapper list={pageContext.keyAccountabilities} />
                </SubColPanel>
              </Box>

              <Box p={2}>
                {/* TODO: scroll-spy="" */}
                <div>
                  <SectionHeader>
                    Preferred Experience And Exposure
                  </SectionHeader>
                </div>

                <SubColPanel>
                  <ListWrapper
                    list={pageContext.preferredExperienceAndExposure}
                  />
                </SubColPanel>
              </Box>
            </DesktopContent>
          </SubCol>
        </Flexbox>
      </Content>
    </AppLayout>
  )
}

export default JobPage
