import React from "react"
import styled from "styled-components"
import { Box, Modal } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import UnstyledLink from "@components/elements/UnstyledLink"
import LateralJobBox from "@components/elements/LateralJobBox"
import { getJobUrl } from "@helpers/url"

import { GRADE_TEXT_MAPPING } from "@constants/index"

import upArrow from "@assets/chart/up-arrow.svg"
import dashedArrow from "@assets/chart/dashed-lateral.png"

const ImgUpArrow = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img src={upArrow} alt="Vectical Movement Arrow" />
    </div>
  )
}

const ImgDownArrow = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={upArrow}
        alt="Vectical Movement Arrow"
        style={{ transform: "rotate(180deg)" }}
      />
    </div>
  )
}

const DashedArrowDiv = styled.div`
  max-width: 32px;
  margin-left: -8px;

  @media (max-width: 1023px) {
    max-width: 50px;
  }
`

const ImgDashedArrow = () => {
  return (
    <DashedArrowDiv>
      <img src={dashedArrow} alt="Lateral Arrow" height="auto" width="100%" />
    </DashedArrowDiv>
  )
}

const PotentialMovementDiv = styled.div`
  background: ${props => props.theme.colors.boxGreen};
  padding: 20px 30px;
  margin: 0 0px 0;

  color: ${props => props.theme.colors.textBlack};
  min-width: 145px;
  text-align: center;
  cursor: pointer;

  div.title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }

  div.section {
    font-weight: 400;
    font-size: 10px;
  }

  div.current-job {
    font-weight: 400;
    font-size: 10px;
  }
`

const PotentialMovementOuterDiv = styled.div`
  background: ${props =>
    props.isCurrent ? `${props.theme.colors.boxGreen}4D` : "none"};
  padding: ${props => (props.isCurrent ? "8px" : "0 8px")};
  margin-top: ${props => (props.isCurrent ? "-8px" : "inherit")};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  justify-items: center;
  align-items: center;

  @media (min-width: 1024px) {
    padding: 16px;
  }

  > div {
    width: 100%;
  }
`

const PathHeader = styled.div`
  color: ${props => props.theme.colors.textBlack};
  text-align: center;
  font-weight: 600;

  padding: 8px 0 24px 0;

  @media (max-width: 1023px) {
    padding: 2rem 1rem;
  }

  .sector {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }

  .department {
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
  }
`

const LevelRow = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: ${props => props.theme.colors.textBlack};
  opacity: 0.2;

  padding: 0 16px 0 0;
`

const Flexbox = styled.div`
  display: flex;
  align-items: center;
`

const LateralFlexbox = styled(Flexbox)`
  align-items: center;
`

const LateralButton = styled.div`
  background: "transparent";
  font-size: 12px;
  color: #4d4d4d;
  padding: 16px;
  border: 1px solid #c6c8ca;
  @media (max-width: 375px) {
    width: 120px;
  }
  text-align: center;
  cursor: pointer;
  user-select: none;

  // display: flex;
  align-items: center;
  justify-content: space-between;
`

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const ModalContentBox = styled(Box)`
  height: 100%;
  max-height: 70vh;
  overflow: scroll;
`

const Chart = props => {
  const currentJob = props
  const { potentialMovements, lateralMovements, isReverse = false } = currentJob

  const modalClasses = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalJobs, setModalJobs] = React.useState({
    currentJob: null,
    lateralJobs: [],
  })

  const handleModalOpen = (currentJob, lateralJobs) => {
    setModalOpen(true)
    setModalJobs({
      ...modalJobs,
      currentJob: currentJob,
      lateralJobs: lateralJobs,
    })
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const isAnyLateralMovement =
    lateralMovements.length > 0 ||
    potentialMovements.some(pm => pm.lateralMovements.length > 0)

  const gridTemplateColumns = isAnyLateralMovement ? "1fr 4fr 4fr" : "1fr 4fr"

  const renderJobGridRow = (job, isRenderJobGrade = false) => {
    const isCurrentJob = job.jobId === currentJob.jobId

    return (
      <>
        {isRenderJobGrade ? (
          <LevelRow>{GRADE_TEXT_MAPPING[job.grade]}</LevelRow>
        ) : (
          <LevelRow />
        )}

        <PotentialMovementOuterDiv isCurrent={isCurrentJob}>
          <UnstyledLink to={getJobUrl(job.jobId)}>
            <PotentialMovementDiv color={job.themeColor}>
              <div className="title">{job.jobTitle}</div>
              <div className="section">{job.section}</div>
              {isCurrentJob ? (
                <div className="current-job">(Current Role)</div>
              ) : (
                ""
              )}
            </PotentialMovementDiv>
          </UnstyledLink>
        </PotentialMovementOuterDiv>

        {job.lateralMovements.length > 0 ? (
          <LateralFlexbox>
            <ImgDashedArrow />
            <LateralButton
              onClick={() => handleModalOpen(job.name, job.lateralMovements)}
            >
              {job.lateralMovements.length > 1
                ? `${job.lateralMovements.length} Lateral Movements`
                : `1 Lateral Movements`}
            </LateralButton>
          </LateralFlexbox>
        ) : isAnyLateralMovement ? (
          <div />
        ) : (
          ""
        )}
      </>
    )
  }

  const renderEmptyJobGridRow = () => {
    return (
      <>
        <LevelRow />
        <Box py={1} />
        {isAnyLateralMovement ? <div /> : ""}
      </>
    )
  }

  const renderPotentialMovementsGrid = potentialMovements => {
    return potentialMovements.map((pm, index) => {
      return (
        <>
          {index === 0 ? "" : renderEmptyJobGridRow()}
          {renderJobGridRow(pm, index === 0)}
        </>
      )
    })
  }

  const renderCurrentJobGrid = () => {
    const renderUpArrow = () => {
      return (
        <>
          <div />
          <div>{isReverse ? <ImgDownArrow /> : <ImgUpArrow />}</div>
          {isAnyLateralMovement ? <div /> : ""}
        </>
      )
    }

    return (
      <>
        {currentJob.potentialMovements.length > 0 ? renderUpArrow() : ""}
        {renderJobGridRow(currentJob, true)}
      </>
    )
  }

  return (
    <Grid columns={gridTemplateColumns}>
      <div />
      <PathHeader>
        <Box className="sector">{currentJob.sector}</Box>
        <Box className="department">{currentJob.department}</Box>
      </PathHeader>
      {isAnyLateralMovement ? <div /> : ""}

      {renderPotentialMovementsGrid(potentialMovements)}
      {renderCurrentJobGrid()}

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={modalClasses.paper}>
          <h2 id="simple-modal-title" style={{ textAlign: "center" }}>
            Select a Lateral Movement
          </h2>

          <ModalContentBox>
            {modalJobs.lateralJobs.map(job => {
              return (
                <div style={{ padding: "8px 0" }}>
                  {/* TODO: urlHelper */}
                  <UnstyledLink to={getJobUrl(job.jobId)}>
                    <LateralJobBox {...job} />
                  </UnstyledLink>
                </div>
              )
            })}
          </ModalContentBox>

          <div style={{ padding: "16px", textAlign: "center" }}>
            <span onClick={() => handleModalClose()}>Close</span>
          </div>
        </div>
      </Modal>
    </Grid>
  )
}

export default Chart
