import React from "react"
import styled from "styled-components"
import { withStyles } from "@material-ui/core/styles"
import { Tooltip, Box } from "@material-ui/core"
import lodashGet from "lodash/get"

import theme from "@assets/styles/theme"
import COMPETENCIES_CONTENT from "@content/competencies.json"
import TECHNICAL_COMPETENCIES_CONTENT from "@content/technical-competencies.json"
import TECHNICAL_COMPETENCIES_LEVELS_CONTENT from "@content/technical-competencies-levels.json"

import { COMPETENCY_TYPES } from "@constants/index"

import iconHint from "@assets/jobs/hint.svg"
import iconCoreCpl from "@assets/jobs/core-competencies-proficiency-leveling.svg"
import iconLeadershipCpl from "@assets/jobs/leadership-competencies-proficiency-leveling.svg"
import iconTechnicalCpl from "@assets/jobs/technical-competencies-proficiency-leveling.svg"

const CompetencyPanel = styled.div`
  flex: 0 0 100%;

  @media (min-width: 1024px) {
    margin: 0 8px 0 0;
    padding: 8px 8px 0 0;
  }

  @media (max-width: 1023px) {
    margin: 0;
    padding: 8px 0;
  }

  .header {
    color: #666;
    font-size: 0.825rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  &.mobile {
    flex: 0 0 100%;
  }
`

const CompetencyPanelItem = styled.div`
  display: block;
  text-decoration: none;
  background: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  border-radius: 25px;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;

  cursor: pointer;

  .name {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }

  .level {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;

    text-align: end;
  }
`

const CompetencyPanelHeaderItem = styled(CompetencyPanelItem)`
  color: ${props => props.theme.colors.textGrey};
  background: transparent;

  padding: 0 1rem;

  cursor: unset;

  .name {
    font-weight: 700;
    font-size: 12px;
  }

  .level {
    font-weight: 400;
    font-size: 10px;
  }

  .level > span {
    padding-right: 8px;
  }

  .level > img {
    width: 12px;

    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`

const CompetencyPanelInnerGrid = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr;
`

const CompetencyTooltip = withStyles({
  tooltip: {
    color: "#FFFFFF",
    backgroundColor: "#000000",
    fontSize: "1rem",
    fontWeight: "300",
    marginTop: "0.5em", // position
    border: `1px solid ${theme.colors.proficiencyTooltipBorder}`,
    minWidth: 400,
  },
  arrow: {
    color: "#000000", // same as tooltip background color
    "&:before": {
      border: `1px solid ${theme.colors.proficiencyTooltipBorder}`,
    },
  },
})(Tooltip)

const CompetencyHeaderLevelTooltip = withStyles({
  tooltip: {
    color: "#2C2C2C",
    backgroundColor: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: "300",
    marginTop: "0.5em", // position
    border: `1px solid ${theme.colors.proficiencyTooltipBorder}`,
    minWidth: 432,
  },
  arrow: {
    color: "#FFFFFF", // same as tooltip background color
    "&:before": {
      border: `1px solid ${theme.colors.proficiencyTooltipBorder}`,
    },
  },
})(Tooltip)

const CompetencyTooltipNameContentBox = styled(Box)`
  .name {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
`

const CompetencyTooltipLevelContentBox = styled(Box)`
  .name {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .type-name {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
`

const CompetencyTooltipLevelContentTypeUl = styled.ul`
  list-style: none;

  margin: 0;
  padding-inline-start: 0em;
  margin-block-end: 8px;

  font-weight: 400;
  font-size: 10px;
  line-height: 14px;

  > li::before {
    content: "\\2022";
    color: white;
    display: inline-block;
    width: 1em;

    position: absolute;
    left: 0;
  }

  > li {
    position: relative;
    padding-left: 16px;
  }
`

const HeaderTooltipContentTitle = styled(Box)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`

const Panel = props => {
  const { competencies, type } = props

  // return { text: '#FFFFFF', background: '#FFFFFF'}
  const getCompetencyPanelColor = level => {
    const typeColors = theme.colors.competencies[type.toLowerCase()]

    return typeColors[level.toLowerCase()] || typeColors.default
  }

  const renderHeaderTooltipContent = type => {
    let icon = iconCoreCpl
    if (type === COMPETENCY_TYPES.LEADERSHIP) {
      icon = iconLeadershipCpl
    } else if (type === COMPETENCY_TYPES.TECHNICAL) {
      icon = iconTechnicalCpl
    }

    return (
      <Box py={1.5} px={2}>
        <HeaderTooltipContentTitle>
          {type} Competencies Proficiency Leveling
        </HeaderTooltipContentTitle>
        <Box pt={1}>
          <img src={icon} alt="icon" />
        </Box>
      </Box>
    )
  }

  const renderCompetencyTooltipNameContent = (name, description) => {
    return (
      <CompetencyTooltipNameContentBox p={2}>
        <Box className="name">{name}</Box>
        <p>{description}</p>
      </CompetencyTooltipNameContentBox>
    )
  }

  const renderCompetencyTooltipLevelContent = (level, foundCompetency) => {
    const matchedLevel = foundCompetency.levels.find(
      fcLevel => fcLevel.slug === level.toLowerCase()
    )

    const matchedLevelTypes = lodashGet(matchedLevel, "types", [])

    return (
      <CompetencyTooltipLevelContentBox p={2}>
        <Box className="name" py={1}>
          {level}
        </Box>
        {matchedLevelTypes.map((mlt, mltIndex) => {
          return (
            <React.Fragment key={`mlt-fragment-${mltIndex}`}>
              <Box className="type-name" py={1}>
                {mlt.name}
              </Box>
              {mlt.definitions.map((def, defIndex) => {
                return (
                  <CompetencyTooltipLevelContentTypeUl
                    key={`competency-tooltip-level-content-type-${defIndex}`}
                  >
                    <li>{def}</li>
                  </CompetencyTooltipLevelContentTypeUl>
                )
              })}
            </React.Fragment>
          )
        })}
      </CompetencyTooltipLevelContentBox>
    )
  }

  const renderTechnicalCompetencyTooltipLevelContent = level => {
    let matchedLevel = TECHNICAL_COMPETENCIES_LEVELS_CONTENT.find(
      tcLevel => tcLevel.slug === level.toLowerCase()
    )

    if (!matchedLevel) {
      matchedLevel = TECHNICAL_COMPETENCIES_LEVELS_CONTENT[0]
    }

    return (
      <CompetencyTooltipLevelContentBox p={2}>
        <Box className="name" py={1}>
          {level}
        </Box>
        {matchedLevel.definitions.map((def, defIndex) => {
          return (
            <CompetencyTooltipLevelContentTypeUl
              key={`competency-tooltip-level-content-type-${defIndex}`}
            >
              <li>{def}</li>
            </CompetencyTooltipLevelContentTypeUl>
          )
        })}
      </CompetencyTooltipLevelContentBox>
    )
  }

  const renderTechnicalCompetencyPanelItems = competencies => {
    return (
      <>
        {competencies.map((competency, index) => {
          let foundCompetency = TECHNICAL_COMPETENCIES_CONTENT.find(
            elem => elem.slug === competency.slug
          )

          if (!foundCompetency) {
            return "" // !!! do not show
          }

          const panelColor = getCompetencyPanelColor(
            competency.level.toLowerCase()
          )
          const textColor = panelColor.text
          const backgroundColor = panelColor.background

          return (
            <CompetencyPanelItem
              key={`${type}-competency-${index}`}
              backgroundColor={backgroundColor}
              textColor={textColor}
            >
              <CompetencyPanelInnerGrid>
                <div>
                  <CompetencyTooltip
                    title={renderCompetencyTooltipNameContent(
                      foundCompetency.name,
                      foundCompetency.description
                    )}
                    arrow
                  >
                    <span className="name">{foundCompetency.name}</span>
                  </CompetencyTooltip>
                </div>

                <div className="level">
                  <CompetencyTooltip
                    title={renderTechnicalCompetencyTooltipLevelContent(
                      competency.level
                    )}
                    arrow
                  >
                    <span>{competency.level}</span>
                  </CompetencyTooltip>
                </div>
              </CompetencyPanelInnerGrid>
            </CompetencyPanelItem>
          )
        })}
      </>
    )
  }

  const renderOtherCompetencyPanelItems = competencies => {
    return (
      <>
        {competencies.map((competency, index) => {
          let foundCompetency = COMPETENCIES_CONTENT.find(
            elem => elem.slug === competency.slug
          )

          const panelColor = getCompetencyPanelColor(
            competency.level.toLowerCase()
          )
          const textColor = panelColor.text
          const backgroundColor = panelColor.background

          return (
            <CompetencyPanelItem
              key={`${type}-competency-${index}`}
              backgroundColor={backgroundColor}
              textColor={textColor}
            >
              <CompetencyPanelInnerGrid>
                <div>
                  <CompetencyTooltip
                    title={renderCompetencyTooltipNameContent(
                      foundCompetency.name,
                      foundCompetency.description
                    )}
                    arrow
                  >
                    <span className="name">{foundCompetency.name}</span>
                  </CompetencyTooltip>
                </div>

                <div className="level">
                  <CompetencyTooltip
                    title={renderCompetencyTooltipLevelContent(
                      competency.level,
                      foundCompetency
                    )}
                    arrow
                  >
                    <span>{competency.level}</span>
                  </CompetencyTooltip>
                </div>
              </CompetencyPanelInnerGrid>
            </CompetencyPanelItem>
          )
        })}
      </>
    )
  }

  return (
    <CompetencyPanel>
      <CompetencyPanelHeaderItem>
        <CompetencyPanelInnerGrid>
          <span className="name">{type} Competencies</span>
          <div className="level">
            <span>Proficiency Levels</span>
            <CompetencyHeaderLevelTooltip
              title={renderHeaderTooltipContent(type)}
              placement="top"
              arrow
            >
              <img src={iconHint} alt="icon" />
            </CompetencyHeaderLevelTooltip>
          </div>
        </CompetencyPanelInnerGrid>
      </CompetencyPanelHeaderItem>

      {type === COMPETENCY_TYPES.TECHNICAL
        ? renderTechnicalCompetencyPanelItems(competencies)
        : renderOtherCompetencyPanelItems(competencies)}
    </CompetencyPanel>
  )
}

export default Panel
